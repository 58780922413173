import { FC, useState, ChangeEvent } from 'react';
import { Link as ScrollLink, scroller } from 'react-scroll';
import 'antd/dist/antd.css';
import './Panel.css';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import StaffListPanel from 'components/molecules/StaffListPanel';
import ResultAddressDialog from 'components/molecules/ResultAddressDialog';
import InvoiceAddressDialog from 'components/molecules/InvoiceAddressDialog';
import DestinationResultDialog from 'components/molecules/DestinationResultDialog';
import DestinationInvoiceDialog from 'components/molecules/DestinationInvoiceDialog';
import { BillingInfo, StaffListModel, User } from 'types/User';
import { ErrorInfo, Pic, Request } from 'types/RequestDataInfo';
import { rootDir } from 'utils/CommonConst';
import { colors } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CustomTextField2 from './CustomTextField2';
import DestinationPanel, { Destination } from './DestinationPanel';
import CommonDialog from './CommonDialog';

const theme1 = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
  },
  palette: {
    primary: { main: colors.blue[500] }, // テーマの色
    secondary: { main: colors.indigo[900] }, // テーマの色
  },
});

const theme2 = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(1, 5, 0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
      minWidth: 825,
    },
    titleSpace: {
      margin: theme.spacing(2, -3, 0),
    },
    titleTop: {
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(2, -1, 2),
    },
    radio: {
      margin: theme.spacing(1.5, 0, 0),
    },
    interval: {
      margin: theme.spacing(3, -3, 0),
    },
    button: {
      margin: theme.spacing(2, 3, 2),
    },
    addressTooltip: {
      margin: theme.spacing(2, 0, 2),
      '&:hover': {
        background: 'gray',
      },
    },
    staffTooltip: {
      margin: theme.spacing(2, 1, 2, 3),
      '&:hover': {
        background: 'gray',
      },
    },
    size: {
      width: 60,
    },
    space: {
      margin: theme.spacing(1.5, 2, 0),
    },
    spaceFaxLabel: {
      fontSize: '14px',
      margin: theme.spacing(1.5, 2, 0),
    },
    spaceFax: {
      margin: theme.spacing(1.5, 0, 0),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    noWidth: {
      width: 90,
    },
    width: {
      width: 250,
    },
    length: {
      width: 470,
    },
    titleForRadio: {
      marginRight: 50,
    },
    toTopDiv: {
      textAlign: 'right',
      margin: theme.spacing(5, 0, 0),
    },
    toTopButton: {
      width: 100,
      margin: theme.spacing(0, 0, 5),
    },
    toNextButton: {
      margin: theme.spacing(0, 2, 5),
    },
    error: {
      backgroundColor: 'red',
      color: 'white',
    },
    aboutStaff: {
      fontSize: 16,
    },
  }),
);

export type Result = {
  id: number;
  forJapanese: string;
  forEnglish: string;
};

export type Invoice = {
  id: number;
  name: string;
  postalCode: string;
  address1: string;
  address2: string;
};

export type BasicInfoPanelProps = {
  userData?: User;
  billingData?: BillingInfo;
  picData?: StaffListModel[];
  requestData?: Request;
  updateRequest: (value: Request) => void;
  tabSet: (value: number) => void;
  errorInfo?: ErrorInfo;
  check: () => boolean;
};
/* non eslint-disable */
const BasicInfoPanel: FC<BasicInfoPanelProps> = ({
  userData,
  billingData,
  picData,
  requestData,
  updateRequest,
  tabSet,
  errorInfo,
  check,
}) => {
  const classes = useStyles();

  const [messages, setMessages] = useState<JSX.Element[]>();
  const [commDlg, setCommDlg] = useState(false);
  const [openResultAddress, setOpenResultAddress] = useState(false);

  const handleClickOpenResultAddress = () => {
    setOpenResultAddress(true);
  };

  const handleCloseResultAddress = () => {
    setOpenResultAddress(false);
  };

  const [openInvoiceAddress, setOpenInvoiceAddress] = useState(false);

  const handleClickOpenInvoiceAddress = () => {
    setOpenInvoiceAddress(true);
  };

  const handleCloseInvoiceAddress = () => {
    setOpenInvoiceAddress(false);
  };

  const [openDestinationResult, setOpenDestinationResult] = useState(false);

  const handleClickOpenDestinationResult = () => {
    setOpenDestinationResult(true);
  };

  const handleCloseDestinationResult = () => {
    setOpenDestinationResult(false);
  };

  const [openDestinationInvoice, setOpenDestinationInvoice] = useState(false);

  const handleClickOpenDestinationInvoice = () => {
    setOpenDestinationInvoice(true);
  };

  const handleCloseDestinationInvoice = () => {
    setOpenDestinationInvoice(false);
  };

  // トップへ移動
  const scrollToTop = () => {
    scroller.scrollTo('TopPage', {
      duration: 250,
      delay: 100,
      smooth: true,
      containerId: 'HeaderContent',
      offset: -150, // Scrolls to element + 50 pixels down the page
    });
  };

  // 速報有無
  const changeIsPreliminaryReport = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = event.target.value.toLowerCase() === 'true';
    data.isPreliminaryReport = value;
    updateRequest(data);
  };
  // 請求書送付先情報
  const changePreliminaryReportType = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const data = { ...requestData } as Request;
    const value = Number(event.target.value);
    data.preliminaryReportType = value;
    if (value === 2) {
      data.preliminaryReportFax = userData?.fax ?? '';
    }
    updateRequest(data);
  };
  // 結果書引渡方法
  const changeSendReportType = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = Number(event.target.value);
    data.sendReportType = value;
    if (data.sendNameFlgSameRcpt && userData) {
      data.sendPic =
        data.picList?.find((n) => n.picType === 1 && n.mailSendType === 1)
          ?.name ?? '';
    }
    updateRequest(data);
  };

  // 請求書に記載される宛名
  const changeChargeFlgSameRcpt = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = event.target.value.toLowerCase() === 'true';
    data.chargeFlgSameRcpt = value;
    if (value && userData) {
      // 依頼者と同じ
      data.chargeName = userData.custFullName;
      data.chargeZipCode = userData.zip;
      data.chargeAddress1 = userData.address1;
      data.chargeAddress2 = userData.address2;
    } else {
      // その他
      data.chargeName = '';
      data.chargeZipCode = '';
      data.chargeAddress1 = '';
      data.chargeAddress2 = '';
    }
    updateRequest(data);
  };

  // 結果書送付先情報
  const changeSendNameFlgSameRcpt = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = event.target.value.toLowerCase() === 'true';
    data.sendNameFlgSameRcpt = value;
    if (value && userData) {
      // 依頼者と同じ
      data.sendName = userData.custFullName;
      data.sendZipCode = userData.zip;
      data.sendAddress1 = userData.address1;
      data.sendAddress2 = userData.address2;
      data.sendTel = userData.tel;
      data.sendFax = userData.fax;
      data.sendPic =
        data.picList?.find((n) => n.picType === 1 && n.mailSendType === 1)
          ?.name ?? '';
      // } else {
      //   // その他
      //   data.sendName = '';
      //   data.sendZipCode = '';
      //   data.sendAddress1 = '';
      //   data.sendAddress2 = '';
      //   data.sendTel = '';
      //   data.sendFax = '';
    }
    updateRequest(data);
  };

  // 請求書請求方法
  const changeChargeMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = Number(event.target.value);
    data.chargeMethod = value;
    if (value === 1 && billingData) {
      const text = `月次請求書の通知は月次請求設定の担当者へ送信されますがよろしいですか。`;
      const breakedText = text.split('¥n').map((line) => (
        <span key={line}>
          {line}
          <br />
        </span>
      ));

      setMessages(breakedText);
      setCommDlg(true);
      data.chargeSendPic = billingData.picName;
    }
    updateRequest(data);
  };

  // 請求書引渡方法
  const changeChargeReportType = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = Number(event.target.value);
    data.chargeReportType = value;
    if (data.chargeSendOption === 0 && userData) {
      data.chargeSendPic =
        data.picList?.find((n) => n.picType === 1 && n.mailSendType === 1)
          ?.name ?? '';
    }
    updateRequest(data);
  };

  // 請求書送付先情報
  const changeChargeSendOption = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = Number(event.target.value);
    data.chargeSendOption = value;
    if (value === 0 && userData) {
      // 依頼者と同じ
      data.chargeSendName = userData.custFullName;
      data.chargeSendZipCode = userData.zip;
      data.chargeSendAddress1 = userData.address1;
      data.chargeSendAddress2 = userData.address2;
      data.chargeSendPicTel = userData.tel;
      data.chargeSendPicFax = userData.fax;
      data.chargeSendPic =
        data.picList?.find((n) => n.picType === 1 && n.mailSendType === 1)
          ?.name ?? '';
    } else if (value === 1 && billingData) {
      // 月次請求情報と同じ
      data.chargeSendName = billingData.name;
      data.chargeSendZipCode = billingData.zip;
      data.chargeSendAddress1 = billingData.address1;
      data.chargeSendAddress2 = billingData.address2;
      data.chargeSendPicTel = billingData.tel;
      data.chargeSendPicFax = billingData.fax;
      data.chargeSendPic = billingData.picName;
      // } else {
      //   // その他
      //   data.chargeSendName = '';
      //   data.chargeSendZipCode = '';
      //   data.chargeSendAddress1 = '';
      //   data.chargeSendAddress2 = '';
      //   data.chargeSendPicTel = '';
      //   data.chargeSendPicFax = '';
      //   data.chargeSendPic = '';
    }
    updateRequest(data);
  };

  // 速報FAX番号
  const changePreliminaryReportFax = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = String(event.target.value);
    data.preliminaryReportFax = value;
    updateRequest(data);
  };

  // 結果書部数
  const changeSendReportNum = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = Number(event.target.value);
    if (value > 3) {
      data.sendReportNum = 3;
    } else if (value < 0) {
      data.sendReportNum = 0;
    } else {
      data.sendReportNum = value;
    }
    updateRequest(data);
  };

  // 結果書部数
  const changeSendReportNumEn = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    const value = Number(event.target.value);
    if (value > 3) {
      data.sendReportNumEn = 3;
    } else if (value < 0) {
      data.sendReportNumEn = 0;
    } else {
      data.sendReportNumEn = value;
    }
    updateRequest(data);
  };

  const textAddress =
    '結果書の宛名について ¥n 結果書の宛名として使用されます。';
  const breakedTextAddress = textAddress.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  const textStaff =
    '担当者について¥n ・依頼担当者には依頼の通知メール等が届きます。¥n ・結果書担当者には結果書についての通知メール等が届きます。¥n ・請求書担当者には請求書についての通知メール等が届きます。';
  const breakedTextStaff = textStaff.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  const textMonthlyCharge =
    '月次請求設定を行っている場合のみ ¥n 選択可能。 ¥n 変更は「アカウント管理」 ⇒ 「月次請求設定」';
  const breakedTextMonthlyCharge = textMonthlyCharge.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  const textPreliminaryFax1 =
    'FAX番号が登録されていません。¥n今後もFAXをご利用の場合、¥n「アカウント管理」 ⇒ 「基本情報」¥nでFAX番号を登録してください。¥nここで入力したFAX番号はこの依頼でのみ有効です。';
  const breakedTextPreliminaryFax1 = textPreliminaryFax1
    .split('¥n')
    .map((line) => (
      <span key={line} style={{ fontSize: '15px' }}>
        {line}
        <br />
      </span>
    ));

  const textPreliminaryFax2 =
    'ここで変更したFAX番号はこの依頼でのみ有効です。¥n FAX番号の初期表示を変更する場合は¥n「アカウント管理」 ⇒ 「基本情報」で変更してください。';
  const breakedTextPreliminaryFax2 = textPreliminaryFax2
    .split('¥n')
    .map((line) => (
      <span key={line} style={{ fontSize: '15px' }}>
        {line}
        <br />
      </span>
    ));

  const results: Result[] = [
    {
      id: 1,
      forJapanese: requestData?.namePublishFor1 ?? '',
      forEnglish: requestData?.namePublishFor1En ?? '',
    },
    {
      id: 2,
      forJapanese: requestData?.namePublishFor2 ?? '',
      forEnglish: requestData?.namePublishFor2En ?? '',
    },
    {
      id: 3,
      forJapanese: requestData?.namePublishFor3 ?? '',
      forEnglish: requestData?.namePublishFor3En ?? '',
    },
  ];

  const invoices: Invoice = {
    id: 1,
    name: requestData?.chargeName ?? '',
    postalCode: requestData?.chargeZipCode ?? '',
    address1: requestData?.chargeAddress1 ?? '',
    address2: requestData?.chargeAddress2 ?? '',
  };

  const resultDestinations: Destination = {
    id: 1,
    name: requestData?.sendName ?? '',
    postalCode: requestData?.sendZipCode ?? '',
    address1: requestData?.sendAddress1 ?? '',
    address2: requestData?.sendAddress2 ?? '',
    tel: requestData?.sendTel ?? '',
    fax: requestData?.sendFax ?? '',
    pic: requestData?.sendPic ?? '',
  };

  const invoiceDestinations: Destination = {
    id: 1,
    name: requestData?.chargeSendName ?? '',
    postalCode: requestData?.chargeSendZipCode ?? '',
    address1: requestData?.chargeSendAddress1 ?? '',
    address2: requestData?.chargeSendAddress2 ?? '',
    tel: requestData?.chargeSendPicTel ?? '',
    fax: requestData?.chargeSendPicFax ?? '',
    pic: requestData?.chargeSendPic ?? '',
  };

  return (
    <div className={classes.paper}>
      <Typography
        id="basictop"
        component="h1"
        variant="h5"
        className={classes.titleSpace}
      >
        依頼者
      </Typography>
      <CommonDialog
        msg={messages}
        isOpen={commDlg}
        doYes={() => {
          setCommDlg(false);
        }}
        doNo={() => {
          const data = { ...requestData } as Request;
          data.chargeMethod = 0;
          updateRequest(data);
          setCommDlg(false);
        }}
      />
      <table className="html-table">
        <tbody>
          <tr>
            <th className={classes.width}>名称</th>
            <td>{userData?.custFullName}</td>
          </tr>
          <tr>
            <th>郵便番号</th>
            <td>{userData?.zip}</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>{userData?.address1}</td>
          </tr>
          <tr>
            <th>建物等</th>
            <td>{userData?.address2}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{userData?.tel}</td>
          </tr>
          <tr>
            <th>ファックス番号</th>
            <td>{userData?.fax}</td>
          </tr>
        </tbody>
      </table>
      <Typography component="h1" variant="h6" className={classes.titleTop}>
        <span className={errorInfo?.error1 === true ? classes.error : ''}>
          結果書に記載される宛名
        </span>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleClickOpenResultAddress}
        >
          編集
        </Button>
        <ResultAddressDialog
          open={openResultAddress}
          onClickClose={handleCloseResultAddress}
          userData={userData}
          requestData={requestData}
          updateRequest={(values: Request) => {
            updateRequest(values);
          }}
        />
        <MuiThemeProvider theme={theme2}>
          <Tooltip title={breakedTextAddress} placement="right">
            <Button
              variant="contained"
              color="primary"
              className={classes.addressTooltip}
            >
              宛名？
            </Button>
          </Tooltip>
        </MuiThemeProvider>
      </Typography>
      <table className="html-table">
        <tbody>
          <tr>
            <th className={classes.noWidth}>番号</th>
            <th className={classes.length}>和文用</th>
            <th className={classes.length}>英文用</th>
          </tr>
          {results.map((result) => (
            <tr key={result.id}>
              <td className="center">宛名{result.id}</td>
              <td>{result.forJapanese}</td>
              <td>{result.forEnglish}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Typography component="h1" variant="h6" className={classes.titleTop}>
        <span className={classes.titleForRadio}>請求書に記載される宛名</span>
        <FormControl className={classes.radio}>
          <RadioGroup
            row
            aria-label="gender"
            value={requestData?.chargeFlgSameRcpt ?? true}
            onChange={changeChargeFlgSameRcpt}
          >
            <FormControlLabel
              value
              control={<Radio color="primary" />}
              label="依頼者と同じ"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="その他"
            />
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClickOpenInvoiceAddress}
          disabled={requestData?.chargeFlgSameRcpt}
        >
          編集
        </Button>
        <InvoiceAddressDialog
          open={openInvoiceAddress}
          onClickClose={handleCloseInvoiceAddress}
          requestData={requestData}
          updateRequest={(values: Request) => {
            updateRequest(values);
          }}
        />
      </Typography>
      <table className="html-table">
        <tbody>
          <tr>
            <th className={classes.width}>名称</th>
            <td>{invoices.name}</td>
          </tr>
          <tr>
            <th>郵便番号</th>
            <td>{invoices.postalCode}</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>{invoices.address1}</td>
          </tr>
          <tr>
            <th>建物等</th>
            <td>{invoices.address2}</td>
          </tr>
        </tbody>
      </table>
      <Typography component="h1" variant="h5" className={classes.interval}>
        <span
          className={
            errorInfo?.error3 === true ||
            errorInfo?.error4 === true ||
            errorInfo?.error5 === true
              ? classes.error
              : ''
          }
        >
          各担当者登録
        </span>
        <MuiThemeProvider theme={theme2}>
          <Tooltip title={breakedTextStaff} placement="right">
            <Button
              variant="contained"
              color="primary"
              className={classes.staffTooltip}
            >
              担当者？
            </Button>
          </Tooltip>
          <a
            href={`${rootDir}aboutstaff`}
            target="_blank"
            className={classes.aboutStaff}
            rel="noreferrer"
          >
            詳細はこちら
          </a>
        </MuiThemeProvider>
      </Typography>
      <StaffListPanel
        title="依頼者"
        pics={requestData?.picList ?? []}
        picAll={picData ?? []}
        updatePic={(pic: Pic[]) => {
          const data = { ...requestData } as Request;
          data.picList = pic;
          if (requestData?.sendNameFlgSameRcpt) {
            data.sendPic =
              data.picList?.find((n) => n.picType === 1 && n.mailSendType === 1)
                ?.name ?? '';
          }
          if (requestData?.chargeSendOption === 0) {
            data.chargeSendPic =
              data.picList?.find((n) => n.picType === 1 && n.mailSendType === 1)
                ?.name ?? '';
          }
          updateRequest(data);
        }}
        picType={1}
      />
      <StaffListPanel
        title="結果書"
        pics={requestData?.sendPICList ?? []}
        picAll={picData ?? []}
        updatePic={(pic: Pic[]) => {
          const data = { ...requestData } as Request;
          data.sendPICList = pic;
          updateRequest(data);
        }}
        picType={2}
        copyPic={() => {
          const data = { ...requestData } as Request;
          const pic = data.picList.map((apic) => {
            const target = { ...apic };
            target.picType = 2;

            return target;
          });
          data.sendPICList = pic;
          updateRequest(data);
        }}
      />
      <StaffListPanel
        title="請求書"
        pics={requestData?.chargePICList ?? []}
        picAll={picData ?? []}
        updatePic={(pic: Pic[]) => {
          const data = { ...requestData } as Request;
          data.chargePICList = pic;
          updateRequest(data);
        }}
        picType={3}
        copyPic={() => {
          const data = { ...requestData } as Request;
          const pic = data.picList.map((apic) => {
            const target = { ...apic };
            target.picType = 3;

            return target;
          });
          data.chargePICList = pic;
          updateRequest(data);
        }}
        buttonEnable={requestData?.chargeMethod === 0}
      />
      <Typography component="h1" variant="h5" className={classes.titleSpace}>
        速報
      </Typography>
      <Box className={classes.flex}>
        <FormControl className={classes.radio}>
          <RadioGroup
            row
            aria-label="gender"
            value={requestData?.isPreliminaryReport ?? false}
            onChange={changeIsPreliminaryReport}
          >
            <FormControlLabel
              value
              control={<Radio color="primary" />}
              label="必要"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="不要"
            />
          </RadioGroup>
        </FormControl>{' '}
      </Box>
      <Box
        className={classes.flex}
        hidden={!requestData?.isPreliminaryReport ?? true}
      >
        <span className={classes.space}>引渡方法：</span>
        <FormControl className={classes.radio}>
          <RadioGroup
            row
            aria-label="gender"
            value={requestData?.preliminaryReportType ?? 0}
            onChange={changePreliminaryReportType}
          >
            <FormControlLabel
              value={0}
              control={<Radio color="primary" />}
              label="本システム（Web）"
            />
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label="メール"
            />
            <MuiThemeProvider theme={theme1}>
              <Tooltip
                title={
                  userData?.fax === ''
                    ? breakedTextPreliminaryFax1
                    : breakedTextPreliminaryFax2
                }
                placement="top"
              >
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label="FAX"
                />
              </Tooltip>
            </MuiThemeProvider>
          </RadioGroup>
        </FormControl>
        <span
          className={classes.spaceFaxLabel}
          hidden={requestData?.preliminaryReportType !== 2}
        >
          FAX番号：
        </span>
        <CustomTextField2
          className={classes.spaceFax}
          variant="outlined"
          margin="dense"
          required
          id="fax"
          name="fax"
          autoComplete="fax"
          inputProps={{ maxLength: 20 }}
          hidden={requestData?.preliminaryReportType !== 2}
          value={requestData?.preliminaryReportFax ?? ''}
          onChange={changePreliminaryReportFax}
        />
      </Box>
      <Typography component="h1" variant="h5" className={classes.titleSpace}>
        <span className={errorInfo?.error6 === true ? classes.error : ''}>
          結果書引渡方法
        </span>
      </Typography>
      <Box className={classes.flex}>
        <FormControl className={classes.radio}>
          <RadioGroup
            row
            aria-label="gender"
            value={requestData?.sendReportType ?? 0}
            onChange={changeSendReportType}
          >
            <FormControlLabel
              value={0}
              control={<Radio color="primary" />}
              label="本システム（Web）"
            />
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label="郵送"
            />
          </RadioGroup>
        </FormControl>
        <span className={classes.space} hidden={!requestData?.namePublishForEn}>
          和文
        </span>
        <TextField
          variant="outlined"
          margin="dense"
          required
          name="number"
          id="number"
          type="number"
          className={classes.size}
          disabled={requestData?.sendReportType === 0}
          value={requestData?.sendReportNum ?? ''}
          onChange={changeSendReportNum}
        />
        <span className={classes.space} hidden={!requestData?.namePublishForEn}>
          部
        </span>
        <span className={classes.space} hidden={requestData?.namePublishForEn}>
          部（最大3部まで）
        </span>
        <span className={classes.space} hidden={!requestData?.namePublishForEn}>
          英文
        </span>
        <TextField
          variant="outlined"
          margin="dense"
          required
          name="numberen"
          id="numberen"
          type="number"
          className={classes.size}
          disabled={requestData?.sendReportType === 0}
          value={requestData?.sendReportNumEn ?? ''}
          onChange={changeSendReportNumEn}
          hidden={!requestData?.namePublishForEn}
        />
        <span className={classes.space} hidden={!requestData?.namePublishForEn}>
          部（合計最大3部まで）
        </span>
      </Box>
      <div hidden={requestData?.sendReportType === 0}>
        <Typography component="h1" variant="h6">
          <span className={classes.titleForRadio}>結果書送付先情報</span>
          <FormControl className={classes.radio}>
            <RadioGroup
              row
              aria-label="gender"
              value={requestData?.sendNameFlgSameRcpt ?? true}
              onChange={changeSendNameFlgSameRcpt}
            >
              <FormControlLabel
                value
                control={<Radio color="primary" />}
                label="依頼者と同じ"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="その他"
              />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleClickOpenDestinationResult}
            disabled={requestData?.sendNameFlgSameRcpt}
          >
            編集
          </Button>
          <DestinationResultDialog
            open={openDestinationResult}
            onClickClose={handleCloseDestinationResult}
            requestData={requestData}
            updateRequest={(values: Request) => {
              updateRequest(values);
            }}
          />
        </Typography>
        <DestinationPanel destinations={resultDestinations} />
      </div>
      <Typography
        component="h1"
        variant="h5"
        className={classes.titleSpace}
        id="InvoiceContent"
      >
        <span
          className={
            errorInfo?.error7 === true || errorInfo?.error8 === true
              ? classes.error
              : ''
          }
        >
          請求書
        </span>
      </Typography>
      <Box className={classes.flex}>
        <span className={classes.space}>請求方法：</span>
        <FormControl className={classes.radio}>
          <RadioGroup
            row
            aria-label="gender"
            value={requestData?.chargeMethod ?? 0}
            onChange={changeChargeMethod}
          >
            <FormControlLabel
              value={0}
              control={<Radio color="primary" />}
              label="依頼毎"
            />
            <MuiThemeProvider theme={theme1}>
              <Tooltip title={breakedTextMonthlyCharge} placement="right">
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label="月次"
                  disabled={
                    !(
                      billingData?.isMonthlyInvoice && billingData?.isApproved
                    ) ?? true
                  }
                />
              </Tooltip>
            </MuiThemeProvider>
          </RadioGroup>
        </FormControl>
      </Box>
      <Box className={classes.flex}>
        <span className={classes.space}>引渡方法：</span>
        <FormControl className={classes.radio}>
          <RadioGroup
            row
            aria-label="gender"
            value={requestData?.chargeReportType ?? 0}
            onChange={changeChargeReportType}
          >
            <FormControlLabel
              value={0}
              control={<Radio color="primary" />}
              label="本システム（Web）"
              disabled={
                requestData?.chargeMethod === 1
                  ? !billingData?.isWeb ?? false
                  : false
              }
            />
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label="郵送"
              disabled={
                requestData?.chargeMethod === 1
                  ? !billingData?.isPost ?? false
                  : false
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <div hidden={requestData?.chargeReportType === 0}>
        <Typography component="h1" variant="h6" id="chargeinfo">
          <span className={classes.titleForRadio}>請求書送付先情報</span>
          <FormControl className={classes.radio}>
            <RadioGroup
              row
              aria-label="gender"
              value={requestData?.chargeSendOption ?? 0}
              onChange={changeChargeSendOption}
            >
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label="依頼者と同じ"
                disabled={
                  requestData?.chargeMethod === 1 &&
                  requestData?.chargeReportType === 1
                }
              />
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="月次請求情報と同じ"
                disabled={requestData?.chargeMethod === 0}
              />
              <FormControlLabel
                value={2}
                control={<Radio color="primary" />}
                label="その他"
              />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleClickOpenDestinationInvoice}
            disabled={requestData?.chargeSendOption !== 2}
          >
            編集
          </Button>
          <DestinationInvoiceDialog
            open={openDestinationInvoice}
            onClickClose={handleCloseDestinationInvoice}
            requestData={requestData}
            updateRequest={(values: Request) => {
              updateRequest(values);
            }}
          />
        </Typography>
        <DestinationPanel destinations={invoiceDestinations} />
      </div>
      <div className={classes.toTopDiv}>
        <Button
          variant="contained"
          color="primary"
          className={classes.toNextButton}
          onClick={() => {
            if (check()) {
              tabSet(1);
            } else {
              scrollToTop();
            }
          }}
        >
          ②依頼内容の入力へ進む
        </Button>
        <ScrollLink
          to="TopPage"
          smooth
          duration={250}
          containerId="HeaderContent"
          offset={-150}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.toTopButton}
          >
            トップへ
          </Button>
        </ScrollLink>
      </div>
    </div>
  );
};

export default BasicInfoPanel;
